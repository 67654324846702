let center = [53.212447, 50.133709];

function init() {
    let map = new ymaps.Map('map',
    {
        center: center,
        zoom: 16,

    });

    let placemark = new ymaps.Placemark(center, {}, {
        
    });

    map.controls.remove('geolocationControl'); // удаляем геолокацию
    map.controls.remove('searchControl'); // удаляем поиск
    map.controls.remove('trafficControl'); // удаляем контроль трафика
    map.controls.remove('typeSelector'); // удаляем тип

    map.geoObjects.add(placemark);
}



ymaps.ready(init);